<template>
  <div v-if="render" class="container">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <img class="invisible" alt="Volt Logo" height="100" src="@/assets/logo-normal.png">
            <h4 class="text-light ms-1">{{ question.nome }}</h4>
          </div>
          <div class="card-body">
            <form-body :produto_perguntas="question.produto_perguntas"></form-body>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import FormBody from "@/views/form/FormBody.vue";
import FormService from "@/services/form.service";

export default {
  name: "QuestionarioPedidoPropostas",
  components: {FormBody},
  data() {
    return {question: null, render: false}
  },
  methods: {
    async list() {
      let id = this.$route.params.id;
      let response = await new FormService().view(id);
      this.question = await response?.data;
      this.render = true

    },
  },
  mounted() {
    this.list();
  }
}
</script>

<style scoped>

</style>
